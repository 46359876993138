import React from 'react';
import Footer from '../../components/Footer';
import Title from '../../components/Title';
import logo from '../../images/mys-logo.png';
import FaqSection from './../../components/FaqSection';
import './styles.scss';

function FaqPage(props) {
  return (
    <>
      <Title pageTitle="FAQ" />
      <FaqSection
        color="white"
        size="medium"
        title="Frequently Asked Questions"
        subtitle=""
      />
      <Footer
        color="light"
        size="normal"
        logo={logo}
        copyright="© 2020 OfficiRate, an MYS brand. All Rights Reserved."
      />
    </>
  );
}

export default FaqPage;
