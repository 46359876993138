import React from 'react';
import Footer from '../../components/Footer';
import Title from '../../components/Title';
import CtaSection from './../../components/CtaSection';
import FeaturesSection from './../../components/FeaturesSection';
import HeroSection from './../../components/HeroSection';
import StatsSection from './../../components/StatsSection';
import logo from './../../images/mys-logo.png';
import { useRouter } from './../../util/router.js';
import './styles.scss';

function HomePage(props) {
  const router = useRouter();
  return (
    <>
      <Title pageTitle="Home" />
      <HeroSection
        color="white"
        size="medium"
        title="OfficiRate"
        subtitle="We take your feedback from youth sports games and give it to the people who need it, free of charge. Better officiating means better games."
        buttonText="Rate a game"
        image="https://images.pexels.com/photos/46798/the-ball-stadion-football-the-pitch-46798.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
        buttonOnClick={() => {
          router.push('/review');
        }}
      />
      <FeaturesSection
        color="white"
        size="medium"
        title="Features"
        subtitle="Whether you're a player, coach, or spectator, you can use
          our platform to give your officials the feedback that they need to
          be as good as they can be!"
      />

      <StatsSection
        color="white"
        size="medium"
        title="Every year, we track stats from..."
        items={[
          {
            title: 'Organizations',
            stat: 2000,
          },
          {
            title: 'Locations',
            stat: 4000,
          },
          {
            title: 'Games',
            stat: 50000,
          },
        ]}
      />
      <CtaSection
        color="dark"
        size="large"
        title="Great feedback = Better games"
        buttonText="Submit a review"
        buttonOnClick={() => {
          router.push('/review');
        }}
      />
      <Footer
        color="light"
        size="normal"
        logo={logo}
        copyright={`© ${new Date().getFullYear()} OfficiRate, an MYS brand. All Rights Reserved.`}
      />
    </>
  );
}

export default HomePage;
