import { ApolloProvider } from '@apollo/react-hooks';
import ApolloClient from 'apollo-boost';
import React, { useState } from 'react';
import logo from '../../images/mys-logo.png';
import ThankYouPage from '../thankyou';
import Navbar from './../../components/Navbar';
import { ProvideAuth } from './../../util/auth.js';
import {
  FooterContext,
  MatchContext,
  UserInfoContext,
} from './../../util/contexts';
import { Route, Router, Switch } from './../../util/router.js';
import AboutPage from './../about';
import ChangepassPage from './../changepass';
import ContactPage from './../contact';
import DashboardPage from './../dashboard';
import FaqPage from './../faq';
import ForgotpassPage from './../forgotpass';
import HomePage from './../home';
import PrivacyPolicy from './../privacy';
import ReviewPage from './../review';
import SigninPage from './../signin';
import SignupPage from './../signup';
import WelcomePage from './../welcome';
import './styles.scss';

const apiHost =
  process.env.NODE_ENV === 'production'
    ? 'http://34.83.68.16'
    : 'http://localhost:4200';

const client = new ApolloClient({
  uri: apiHost + '/graphql',
});

function App(props) {
  const [currentMatch, setCurrentMatch] = useState({
    setMatch: (match) => setCurrentMatch({ ...currentMatch, match }),
  });
  const [userInfo, setUserInfoContext] = useState({
    setUserInfo: (newInfo) => setUserInfoContext({ ...userInfo, newInfo }),
  });
  return (
    <FooterContext.Provider>
      <MatchContext.Provider value={currentMatch}>
        <UserInfoContext.Provider value={userInfo}>
          <ProvideAuth>
            <ApolloProvider client={client}>
              <Router>
                <>
                  <Navbar
                    color="white"
                    spaced={true}
                    // logo="https://uploads.divjoy.com/logo.svg"
                    logo={logo}
                  />
                  <div className="mt-5">
                    <Switch>
                      <Route exact path="/" component={HomePage} />

                      <Route exact path="/about" component={AboutPage} />

                      <Route exact path="/faq" component={FaqPage} />

                      <Route
                        exact
                        path="/review"
                        param
                        component={ReviewPage}
                      />

                      <Route exact path="/welcome" component={WelcomePage} />

                      <Route exact path="/privacy" component={PrivacyPolicy} />

                      <Route exact path="/contact" component={ContactPage} />

                      <Route exact path="/thankyou" component={ThankYouPage} />

                      <Route
                        exact
                        path="/dashboard"
                        component={DashboardPage}
                      />

                      <Route exact path="/signin" component={SigninPage} />

                      <Route exact path="/signup" component={SignupPage} />

                      <Route
                        exact
                        path="/forgotpass"
                        component={ForgotpassPage}
                      />

                      <Route
                        exact
                        path="/changepass"
                        component={ChangepassPage}
                      />

                      <Route
                        component={({ location }) => {
                          return (
                            <div
                              style={{
                                padding: '50px',
                                width: '100%',
                                textAlign: 'center',
                              }}
                            >
                              The page <code>{location.pathname}</code> could
                              not be found.
                            </div>
                          );
                        }}
                      />
                    </Switch>
                  </div>
                </>
              </Router>
            </ApolloProvider>
          </ProvideAuth>
        </UserInfoContext.Provider>
      </MatchContext.Provider>
    </FooterContext.Provider>
  );
}

export default App;
