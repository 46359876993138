import React, { useState } from "react";
import Section from "./../Section";
import SectionHeader from "../SectionHeader";
import CountUp from "react-countup";
import "./styles.scss";
import VisibilitySensor from "react-visibility-sensor";

function StatsSection(props) {
  return (
    <Section color={props.color} size={props.size}>
      <div className="columns is-vcentered has-text-centered-mobile is-centered is-variable is-8">
        <div className="column is-narrow">
          <SectionHeader
            title={props.title}
            subtitle={props.subtitle}
            size={3}
          />
        </div>
      </div>
      <nav className="StatsSection__level level mt-3">
        {props.items.map((item, index) => (
          <div className="level-item has-text-centered" key={index}>
            <div>
              <p className="heading">{item.title}</p>
              <CountUp
                start={0}
                end={item.stat}
                duration={2.75}
                separator=","
                suffix=" +"
                redraw={false}
              >
                {({ countUpRef, start }) => (
                  <div>
                    <VisibilitySensor onChange={start} delayedCall>
                      <p className="title" ref={countUpRef} />
                    </VisibilitySensor>
                  </div>
                )}
              </CountUp>
            </div>
          </div>
        ))}
      </nav>
    </Section>
  );
}

export default StatsSection;
