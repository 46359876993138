import React, { useContext, useEffect } from "react";

import {
  AutoComplete,
  Form,
  DatePicker,
  Select,
  TimePicker,
  Input,
  Icon,
  Row,
  Col
} from "antd";
import { useQuery } from "@apollo/react-hooks";
import { GET_NEARBY_ORGANIZATIONS } from "../../util/queries";
import { MatchContext, UserInfoContext } from "../../util/contexts";

const FormItem = Form.Item;
const { Option } = Select;

function filterOption(inputValue, option) {
  if (
    option.props.children.length &&
    typeof option.props.children[2] === "string"
  ) {
    if (
      option.props.children[2]
        .toUpperCase()
        .indexOf(inputValue.toUpperCase()) !== -1
    ) {
      return true;
    } else {
      for (let i = 0; i < option.props.children.length; i++) {
        let child = option.props.children[i];
        if (child.props) {
          if (
            child.props.children &&
            typeof child.props.children === "string"
          ) {
            if (
              child.props.children
                .toUpperCase()
                .indexOf(inputValue.toUpperCase()) !== -1
            ) {
              return true;
            }
          }
        }
      }
    }
  }
}

const formItemStyle = { padding: "0 28px" };

export function CreateEventModal({ locationData = [], ...props }) {
  const { userInfo, setUserInfo } = useContext(UserInfoContext);

  const {
    loading: organizationLoading,
    error: organizationError,
    data: organizationData
  } = useQuery(GET_NEARBY_ORGANIZATIONS, {
    variables: { loc: [-122.8134778, 38.5471191] }
  });

  const { match, setMatch } = useContext(MatchContext);

  useEffect(() => {
    setMatch(undefined);
  }, [setMatch]);

  function selectLocation(val) {
    const selectedOption = locationData.filter(
      location => location._id === val
    )[0];
    setMatch({ ...match, location: selectedOption });
    return selectedOption;
  }

  let locationOptions = locationData.map(location => (
    <Option key={location._id} value={location._id} label={location.name}>
      <div>{location.name}</div>
      <div>
        {location.address}, {location.city}
      </div>
    </Option>
  ));

  let fieldOptions;
  if (match) {
    if (match.location) {
      if (!match.location.fields) {
        match.location.fields = [];
      }
      match.location.fields.map(field => (
        <Option key={field} value={field} label={field}>
          <div>{field}</div>
          <div>{field}</div>
        </Option>
      ));
    }
  }

  let organizationOptions = organizationData
    ? organizationData.findAllOrganizations.map(organization => (
        <Option
          key={organization._id}
          value={organization._id}
          label={organization.name}
        >
          <div>{organization.name}</div>
          <div>{organization.city}</div>
        </Option>
      ))
    : [];

  let divisionOptions =
    match && match.organization && match.organization.divisions
      ? match.organization.divisions.map(division => (
          <Option key={division._id} value={division._id} label={division.name}>
            <div>{division.name}</div>
            {match.league ? <div>match.league.name</div> : null}
          </Option>
        ))
      : [];

  function selectOrganization(val) {
    const selectedOption = organizationData.findAllOrganizations.filter(
      org => org._id === val
    )[0];
    setMatch({ ...match, organization: selectedOption });
    return selectedOption;
  }

  return (
    <div style={{ ...formItemStyle }}>
      <Row type="flex" justify="space-between">
        <Col xs={24} md={11}>
          <FormItem label="Location">
            <AutoComplete
              className="certain-category-search"
              dropdownClassName="certain-category-search-dropdown"
              dropdownMatchSelectWidth={true}
              size="large"
              onSelect={selectLocation}
              style={{ width: "100%" }}
              dataSource={locationOptions}
              placeholder="Start typing the name of your field"
              optionLabelProp="label"
              filterOption={filterOption}
            >
              <Input
                suffix={
                  <Icon type="search" className="certain-category-icon" />
                }
              />
            </AutoComplete>
          </FormItem>
        </Col>
        {match && match.location && match.location.fields.length > 0 ? (
          <Col xs={24} md={11}>
            <FormItem label="Field">
              <AutoComplete
                className="certain-category-search"
                dropdownClassName="certain-category-search-dropdown"
                dropdownMatchSelectWidth={true}
                dropdownStyle={{ width: 450 }}
                size="large"
                onSelect={() => {}}
                dataSource={fieldOptions}
                placeholder="Field A"
                optionLabelProp="label"
                filterOption={filterOption}
              />
            </FormItem>
          </Col>
        ) : null}
      </Row>
      <Row type="flex" justify="space-between">
        <Col xs={24} md={11}>
          <FormItem label="Club Name">
            <AutoComplete
              className="certain-category-search"
              dropdownClassName="certain-category-search-dropdown"
              dropdownMatchSelectWidth={true}
              dropdownStyle={{ width: 450 }}
              size="large"
              onSelect={selectOrganization}
              dataSource={organizationOptions}
              placeholder="Northern Heights Soccer Club"
              optionLabelProp="label"
              filterOption={filterOption}
            />
          </FormItem>
        </Col>

        <Col xs={24} md={11}>
          <FormItem label="Division">
            <AutoComplete
              className="certain-category-search"
              dropdownClassName="certain-category-search-dropdown"
              dropdownMatchSelectWidth={true}
              dropdownStyle={{ width: 450 }}
              size="large"
              onSelect={() => {}}
              dataSource={divisionOptions}
              placeholder="U12 Girls"
              optionLabelProp="label"
              filterOption={filterOption}
            />
          </FormItem>
        </Col>
      </Row>

      <Row type="flex" justify="space-between">
        <Col xs={24} md={11}>
          <FormItem label="Home team">
            <Input
              size="large"
              placeholder="Ex: Blue Rangers"
              onChange={val =>
                setMatch({ ...match, homeTeam: { name: val.target.value } })
              }
            />
          </FormItem>
        </Col>
        <Col xs={24} md={11}>
          <FormItem label="Away Team">
            <Input
              size="large"
              placeholder="Ex: Yellow Sparks"
              onChange={val =>
                setMatch({ ...match, awayTeam: { name: val.target.value } })
              }
            />
          </FormItem>
        </Col>
      </Row>

      <FormItem label="Start Time">
        <TimePicker
          placeholder="Select a start time"
          use12Hours
          format="h:mm a"
          minuteStep={5}
          size="large"
          onChange={val => setMatch({ ...match, start: val })}
          style={{ width: "100%" }}
        />
      </FormItem>
    </div>
  );
}
