import React from 'react';
import Footer from '../../components/Footer';
import ThankYouSection from '../../components/ThankYouSection';
import Title from '../../components/Title';
import logo from './../../images/mys-logo.png';

function ThankYouPage(props) {
  return (
    <div>
      <Title pageTitle="Thank you" />
      <ThankYouSection
        color="white"
        size="medium"
        title="Review success! 🎉 "
        subtitle=""
        showNameField={true}
        buttonText="Send message"
      />
      <Footer
        color="light"
        size="normal"
        logo={logo}
        copyright="© 2020 OfficiRate, an MYS brand. All Rights Reserved."
      />
    </div>
  );
}

export default ThankYouPage;
