import gql from "graphql-tag";

export const GET_RATING_QUESTIONS = gql`
  query {
    findAllQuestionGroups {
      _id
      name
      questions {
        _id
        name
        prompt
        type
      }
    }
  }
`;

export const GET_NEARBY_LOCATIONS = gql`
  query GET_NEARBY_LOCATIONS($loc: [Float]) {
    findAllLocations(query: { loc: $loc }) {
      _id
      name
      city
      address
      state
      zip
    }
  }
`;

export const GET_NEARBY_ORGANIZATIONS = gql`
  query GET_NEARBY_ORGANIZATIONS($loc: [Float]) {
    findAllOrganizations(query: { loc: $loc }) {
      _id
      name
      city
      loc
      icon
    }
  }
`;

export const GET_MATCHES = gql`
  query GET_MATCHES($locations: [ID]) {
    findAllMatches(query: { locations: $locations }) {
      name
      _id
      start
      location {
        _id
        name
        city
        address
        state
        zip
      }
      homeTeam {
        name
      }
      awayTeam {
        name
      }
      division {
        name
      }
    }
  }
`;
