import { Radio } from 'antd';
import React from 'react';

export default function BooleanQuestion({ question, handleChange, value }) {
  const options = [
    {
      label: 'Yes',
      value: true,
    },
    {
      label: 'No',
      value: false,
    },
  ];

  const onChange = (e) => {
    handleChange(e.target.value);
  };

  return (
    <div className="mt-2 mb-3">
      <h2 className="mb-2">{question.prompt}</h2>
      <Radio.Group
        options={options}
        onChange={onChange}
        value={value}
      ></Radio.Group>
    </div>
  );
}
