import React from 'react';
import Footer from '../../components/Footer';
import ContentSection from './../../components/ContentSection';
import TeamBiosSection from './../../components/TeamBiosSection';
import Title from './../../components/Title';
import logo from './../../images/mys-logo.png';
import './styles.scss';

function AboutPage(props) {
  return (
    <div>
      <Title pageTitle="About" />
      <ContentSection
        color="primary"
        size="large"
        title="Better officiating means better games"
        subtitle="This platform has been a long time coming. Officials tend to be much more receptive to feedback hours after an event. No one likes an angry coach/parent/player!"
      />
      <ContentSection
        size="medium"
        subtitle="There are over 90,000 officials out there that need your feedback to improve."
      />
      <TeamBiosSection
        color="white"
        size="medium"
        title="Check out other MYS products"
        subtitle=""
      />
      <Footer
        color="light"
        size="normal"
        logo={logo}
        copyright="© 2020 OfficiRate, an MYS brand. All Rights Reserved."
      />
    </div>
  );
}

export default AboutPage;
