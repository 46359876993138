import { useQuery } from '@apollo/react-hooks';
import { AutoComplete, Icon, Input, Modal } from 'antd';
import moment from 'moment';
import React, { useContext, useState } from 'react';
import { MatchContext } from '../../util/contexts';
import { formatAMPM } from '../../util/helper';
import { useMobileDetect } from '../../util/hooks';
import { GET_MATCHES, GET_NEARBY_LOCATIONS } from '../../util/queries';
import { CreateEventModal } from '../CreateEventModal';

const { Option, OptGroup } = AutoComplete;

function filterOption(inputValue, option) {
  if (
    option.props?.children?.props?.children.length &&
    option.props?.children?.props?.children[0].props?.children ===
      'Match not here? Add it!'
  ) {
    return true;
  }
  if (
    option.props.children.length &&
    typeof option.props.children[2] === 'string'
  ) {
    if (
      option.props.children[2]
        .toUpperCase()
        .indexOf(inputValue.toUpperCase()) !== -1
    ) {
      return true;
    } else {
      for (let i = 0; i < option.props.children.length; i++) {
        let child = option.props.children[i];
        if (child.props) {
          if (
            child.props.children &&
            typeof child.props.children === 'string'
          ) {
            if (
              child.props.children
                .toUpperCase()
                .indexOf(inputValue.toUpperCase()) !== -1
            ) {
              return true;
            }
          }
        }
      }
    }
  }
}

function Complete({ loc, locations, ...props }) {
  const {
    location: locationloading,
    error: locationerror,
    data: locationData,
  } = useQuery(GET_NEARBY_LOCATIONS, {
    variables: { loc },
  });

  const { match, setMatch } = useContext(MatchContext);

  const [modalVisible, setModalVisible] = useState(false);

  const [confirmLoading, setConfirmLoading] = useState(false);

  function showModal() {
    setModalVisible(true);
  }

  function handleCancel() {
    setMatch(undefined);
    setModalVisible(false);
  }

  function handleOk(e) {
    setConfirmLoading(true);
    setTimeout(() => {
      setModalVisible(false);
      setConfirmLoading(false);
    }, 0);
  }

  let locationIds;
  let dataSource1 = [];
  if (locationData) {
    locationIds = locationData.findAllLocations.map((location) => location._id);
  }

  const { loading: matchesloading, matcherror, data: matchData } = useQuery(
    GET_MATCHES,
    {
      variables: { locations: locationIds },
    }
  );

  if (matchData) {
    const unique = (value, index, self) => {
      if (self && value.location) {
        return (
          self
            .map((obj) => {
              if (obj.location) {
                return obj.location.name;
              }
            })
            .indexOf(value.location.name) === index
        );
      }
    };
    dataSource1 = matchData.findAllMatches.filter(unique).map((item) => {
      return {
        name: item.location.name,
        _id: item.location._id,
        children: [],
      };
    });
    matchData.findAllMatches.forEach((match) => {
      dataSource1.forEach((l) => {
        if (match.location) {
          if (l.name === match.location.name) {
            l.children.push(match);
          }
        }
      });
    });
  }

  const isDesktop = useMobileDetect().isDesktop();
  let options;
  if (false) {
    options = [].concat([
      <Option disabled key="loading">
        <Icon type="loading" />
        {`  `}loading matches...
      </Option>,
    ]);
  } else {
    options = dataSource1
      .map((group) => (
        <OptGroup key={group.name} label={group.name}>
          {group.children.map((opt) => (
            <Option
              key={opt._id}
              value={opt._id}
              label={
                opt.homeTeam && opt.awayTeam
                  ? opt.homeTeam.name + ' vs. ' + opt.awayTeam.name
                  : opt._id
              }
            >
              <div>
                {opt.start ? formatAMPM(new Date(opt.start)) + ` @ ` : null}
                {opt.location.name}
              </div>
              {opt.division && isDesktop ? (
                <span className="certain-search-item-count">
                  {opt.division.name}
                </span>
              ) : (
                ''
              )}

              {opt.homeTeam && opt.awayTeam
                ? opt.homeTeam.name + ' vs. ' + opt.awayTeam.name
                : null}
            </Option>
          ))}
        </OptGroup>
      ))
      .concat([
        <Option disabled key="all" className="show-all">
          <button className="btn-no-style" onClick={showModal}>
            <span className="link">Match not here? Add it!</span>
            <div>
              <sub>(It'll only take a minute!)</sub>
            </div>
          </button>
        </Option>,
      ]);
  }

  const { placeholder = 'Start typing...', onSelect = () => {} } = props;

  function selectValue(val) {
    const matches = dataSource1.map((group) => group.children).flat();
    const selectedOption = matches.filter((match) => match._id === val)[0];
    return onSelect(selectedOption);
  }

  return (
    <div className="certain-category-search-wrapper" style={{ width: 450 }}>
      <AutoComplete
        className="certain-category-search"
        dropdownClassName="certain-category-search-dropdown"
        dropdownMatchSelectWidth={false}
        dropdownStyle={{ width: 450 }}
        size="large"
        onSelect={selectValue}
        style={{ width: '100%' }}
        dataSource={options}
        placeholder={placeholder}
        optionLabelProp="label"
        filterOption={filterOption}
      >
        <Input
          suffix={<Icon type="search" className="certain-category-icon" />}
        />
      </AutoComplete>
      <Modal
        title={`Create Event for ${moment(new Date()).format('dddd')}, ${moment(
          new Date()
        ).format('MMMM Do')}`}
        width={940}
        bodyStyle={{ padding: '28px 0 0' }}
        destroyOnClose
        visible={modalVisible}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <CreateEventModal
          locationData={
            locationData ? locationData.findAllLocations : undefined
          }
          current={{ start: new Date() }}
          dateDisabled
        />
      </Modal>
    </div>
  );
}

export default Complete;
