// ref to https://levelup.gitconnected.com/react-native-typescript-and-react-native-web-an-arduous-but-rewarding-journey-8f46090ca56b
import { Image as RNImage, Text as RNText } from 'react-native-web'; // Let's export everything from react-native-web

export * from 'react-native-web'; // And let's stub out everything that's missing!

export var ViewPropTypes = {
  style: function style() {}
};
RNText.propTypes = {
  style: function style() {}
};
RNImage.propTypes = {
  style: function style() {},
  source: function source() {}
};
export var Text = RNText;
export var Image = RNImage; // export const ToolbarAndroid = {};

export var requireNativeComponent = function requireNativeComponent() {};