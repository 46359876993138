import React from "react";
import { motion } from "framer-motion";

import BackgroundImage from "./../BackgroundImage";
import "./styles.scss";

function Section(props) {
  const {
    color,
    size,
    backgroundImage,
    backgroundImageOpacity,
    children,
    // Passed to section element
    ...otherProps
  } = props;

  return (
    <motion.div
      className="example"
      animate={{ opacity: 1 }}
      transition={{ duration: 2, ease: "easeOut" }}
    >
      <section
        className={
          "SectionComponent hero section is-block is-relative" +
          (color ? ` is-${color}` : "") +
          (size ? ` is-${size}` : "")
        }
        {...otherProps}
      >
        {backgroundImage && (
          <BackgroundImage
            image={backgroundImage}
            opacity={backgroundImageOpacity}
          />
        )}

        {props.children}
      </section>
    </motion.div>
  );
}

export default Section;
