import React, { createContext } from "react";

export const FooterContext = createContext(true);

export const MatchContext = createContext({
  match: {},
  setMatch: () => {}
});

export const UserInfoContext = createContext({
  loc: []
});
