import { Icon } from 'antd';
import { motion } from 'framer-motion';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { FormState } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import QuestionGroup from '../../components/QuestionGroup';
import SectionButton from '../../components/SectionButton';
import {
  createReviewResponse,
  getAllReviewQuestions,
} from '../../util/request';

interface Step2Props {
  formState: FormState<any>;
  setFormState: (b: boolean) => void;
  setStep: (n: number) => void;
  coachId?: string;
  matchId: string;
  handleSubmit: (e: any) => void;
  control: any;
  getValues: () => any;
}

interface ReviewResponse {
  coach?: string;
  match: string;
  booleanResponses: any[];
  ratingResponses: any[];
  textResponses: any[];
}

interface QuestionGroup {
  _id: string;
}

function Step2({
  formState,
  setStep,
  coachId,
  matchId,
  handleSubmit,
  control,
}: Step2Props) {
  const [responses, setResponses] = useState({});
  const [questionGroups, setQuestionGroups] = useState<QuestionGroup[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const history = useHistory();

  useEffect(() => {
    if (!questionGroups.length && !isLoading) {
      setIsLoading(true);
      getAllReviewQuestions()
        .then((response) => {
          setIsLoading(false);
          setQuestionGroups(response);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    }
  }, [questionGroups, isLoading]);

  console.log(formState);

  function prepareReviewResponseForSubmit(responseObj: any) {
    if (
      !Object.keys(responseObj).filter(
        (key: string) => responseObj[key] !== undefined
      ).length
    ) {
      return;
    }
    setIsLoading(true);
    const reviewResponse: ReviewResponse = {
      coach: coachId,
      match: matchId,
      booleanResponses: [],
      ratingResponses: [],
      textResponses: [],
    };
    Object.keys(responseObj).forEach((key) => {
      const val = responseObj[key];
      if (typeof val === 'string') {
        reviewResponse.textResponses.push({ question: key, value: val });
      } else if (typeof val === 'number') {
        reviewResponse.ratingResponses.push({ question: key, value: val });
      } else if (typeof val === 'boolean') {
        reviewResponse.booleanResponses.push({ question: key, value: val });
      }
    });
    createReviewResponse(reviewResponse)
      .then((_) => {
        setIsLoading(false);
        history.push('/thankyou');
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }

  return (
    <div className="rating-page">
      <motion.div animate={{ opacity: 1 }} transition={{ duration: 2 }}>
        {!isLoading ? (
          questionGroups.map((group) => (
            <div key={group._id}>
              <QuestionGroup
                group={group}
                responseObj={{ responses, setResponses }}
                control={control}
              />
              <hr />
            </div>
          ))
        ) : (
          <>
            <Icon
              style={{ fontSize: 40 }}
              type="loading"
              className="mr-3 mt-4"
            />
            <span>Getting your match-specific questions...</span>
          </>
        )}
        <div className="mt-4">
          {coachId ? (
            <SectionButton
              disabled={!formState.isDirty}
              color="primary"
              size="large"
              style={{ width: '100%' }}
              onClick={handleSubmit(prepareReviewResponseForSubmit)}
            >
              Submit
            </SectionButton>
          ) : (
            <>
              <span className="mr-3">
                <SectionButton color="primary" onClick={() => setStep(0)}>
                  Previous
                </SectionButton>
              </span>
              <span>
                <SectionButton color="primary" onClick={() => setStep(2)}>
                  Next
                </SectionButton>
              </span>
            </>
          )}
        </div>
      </motion.div>
    </div>
  );
}

export default Step2;
