import { Avatar, Card, Form, Icon, Row, Tooltip, Typography } from 'antd';
import 'antd/dist/antd.css';
import moment from 'moment';
import React, { useContext, useState } from 'react';
import CalendarMarkable from '../../components/CalendarMarkable';
import Complete from '../../components/Complete';
import FormField from '../../components/FormField';
import SectionButton from '../../components/SectionButton';
import SectionHeader from '../../components/SectionHeader';
import { MatchContext } from '../../util/contexts';
import { formatAMPM } from '../../util/helper';

const { Title } = Typography;

const FormItem = Form.Item;
const { Meta } = Card;

function range(start, end) {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
}

function disabledDate(current) {
  // Can not select days before today and today
  return current && current > moment().endOf('day');
}

const Review = ({ setStep }) => {
  const [location, setLocation] = useState([]);
  const { match, setMatch } = useContext(MatchContext);
  const [formState, setFormState] = useState(0);

  const [zip, setZip] = useState('');
  const [zipError, setZipError] = useState(false);

  const [loading, setLoading] = useState(false);

  const [selectedDates, setSelectedDates] = useState([
    new Date().toISOString(),
  ]);

  function getLocation() {
    if ('geolocation' in navigator) {
      setLoading(true);
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation([position.coords.longitude, position.coords.latitude]);
          setFormState(1);
          setLoading(false);
        },
        () => {
          setLoading(false);
          alert('Unable to get your current location');
        },
        { timeout: 5000 }
      );
    } else {
      setLoading(false);
      alert('Geolocation not available');
    }
  }

  function continueWithLocation() {
    window.scrollTo(0, 0);
    getLocation();
  }

  function continueWithZip() {
    fetch(
      `https://us1.locationiq.com/v1/search.php?key=63be45e38dc5f6&countrycodes=us&postalcode=${zip}&format=json`
    ).then(async (response) => {
      const data = await response.json();
      if (data[0]) {
        setLocation([parseFloat(data[0].lon), parseFloat(data[0].lat)]);
        setFormState(1);
        window.scrollTo(0, 0);
      } else {
        setZipError(true);
      }
    });
  }

  return (
    <div className="wrapper d-mt-3 mb-4">
      {formState === 0 ? (
        <>
          <Row justify="center" type="flex" className="mt-2 mb-2">
            <SectionHeader subtitle="Select the date of your game" size={2} />
          </Row>
          <Row justify="center" type="flex" className="mt-3">
            <CalendarMarkable
              multi={false}
              maxDate={new Date()}
              selectedDates={selectedDates}
              setSelectedDates={(values) => setSelectedDates(values)}
            />
            {/* <DatePicker
              format="MM-DD-YYYY"
              open
              defaultValue={moment(new Date())}
              disabledDate={disabledDate}
              className="datepicker"
              dropdownClassName="datepicker"
              popupStyle={{
                flex: 1,
                flexDirection: 'row',
                textAlign: 'center',
                justifyItems: 'center',
                alignItems: 'center',
                justifySelf: 'center',
                justifyContent: 'center',
                alignSelf: 'center',
              }}
              style={{ flex: 1 }}
              size="large"
            /> */}
            <br />
          </Row>

          <Row justify="center" type="flex" className="mt-2">
            {loading ? (
              <button className="button is-success mx-1" disabled>
                <Icon type="loading" className="mr-1" /> Getting your current
                location
              </button>
            ) : (
              <button
                className="button is-success mx-1"
                onClick={continueWithLocation}
              >
                <Icon type="environment" className="mr-1" /> Continue with
                current location
              </button>
            )}
          </Row>

          <Row justify="center" type="flex" className="mt-3">
            <SectionHeader
              subtitle={
                <span>
                  Or please provide your zip code
                  <Tooltip
                    title="We only use your location to help determine which match you attended."
                    placement="bottom"
                  >
                    <sup>
                      <Icon type="info-circle" className="clickable" />
                    </sup>
                  </Tooltip>
                </span>
              }
              size={2}
            />
          </Row>
          <Row justify="center" type="flex" className="mt-3">
            <FormField
              type="text"
              placeholder="Enter your Zip Code"
              value={zip}
              onChange={(value) => setZip(value)}
            />
          </Row>
          {zipError ? (
            <Row justify="center" type="flex" className="mt-3">
              <span className="error-text">
                You must provide a valid zip code.
              </span>
            </Row>
          ) : null}
          <Row justify="center" type="flex" className="mt-3">
            <button
              className="button is-success mx-1"
              onClick={continueWithZip}
            >
              <Icon type="number" className="mr-1" /> Continue with Zip
            </button>
          </Row>
        </>
      ) : (
        <></>
      )}
      {formState === 1 ? (
        <>
          <Row justify="center" type="flex">
            <SectionHeader
              subtitle="Begin typing info from your game"
              size={2}
            />
          </Row>
          <Row justify="center" type="flex" className="mt-2">
            <Complete
              onSelect={(val) => setMatch(val)}
              placeholder="Team name, start time, age group, etc..."
              loc={location}
            />
          </Row>

          {match ? (
            <>
              <div className="wrapper">
                <Row justify="center" type="flex" className="mt-3 mb-2">
                  <SectionHeader subtitle="Is this your game?" size={2} />
                </Row>
                <Row justify="center" type="flex" className="mt-2">
                  <Card
                    style={{ width: 500 }}
                    cover={
                      match.location ? (
                        <iframe
                          title="map"
                          alt="example"
                          src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyDmk7VXooRcLlGnTsxNLWFvmrH-76X0mr0&q=${match.location.address},${match.location.state},${match.location.zip}`}
                        />
                      ) : null
                    }
                    actions={
                      match.start
                        ? [
                            <div>{formatAMPM(new Date(match.start))}`</div>,
                            <div>
                              {match.division ? match.division.name : null}
                            </div>,
                          ]
                        : null
                    }
                  >
                    <Meta
                      avatar={
                        match.organization ? (
                          <Avatar src={match.organization.icon} />
                        ) : null
                      }
                      title={
                        match.homeTeam && match.awayTeam
                          ? match.homeTeam.name + ' vs. ' + match.awayTeam.name
                          : null
                      }
                      description={match.location ? match.location.name : null}
                    />
                  </Card>
                </Row>

                <Row justify="center" type="flex" className="mt-3">
                  <button
                    className="button is-success mx-1"
                    onClick={() => setStep(1)}
                  >
                    Start rating
                  </button>
                </Row>
              </div>
            </>
          ) : null}

          <div className="mt-3">
            <SectionButton onClick={() => setFormState(0)}>
              Back to location
            </SectionButton>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default Review;
