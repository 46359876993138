import React from 'react';
import './styles.scss';

function Welcome(props) {
  return (
    <div className="columns is-centered is-variable is-5">
      {props.items.map((item, index) => (
        <div className="Welcome__column column" key={index}>
          <div
            className={
              'Welcome__card card' +
              (item.emphasized === true ? ' emphasized' : '')
            }
          >
            <div className="Welcome__card-content card-content">
              <div className="Welcome__period has-text-weight-bold">
                {item.timespan}
              </div>
              {/* <div className="Welcome__price has-text-weight-bold">
                <span className="Welcome__price-symbol is-size-3">$</span>
                <span className="is-size-1">{item.price}</span>
                <span className="Welcome__price-month is-size-4">/m</span>
              </div> */}
              <br></br>
              <p className="Welcome__description">{item.description}</p>
              {/* <div className="Welcome__period has-text-weight-bold">
                Can we email you for feedback on future {item.team} games?
              </div>
              <span className="mt-2">
                <Checkbox defaultChecked className="mr-1" />{" "}
                <strong>Sure, you may email me on game days!</strong>
              </span> */}
              <div className="mt-3">
                <button
                  className="Welcome__button button is-medium is-primary"
                  onClick={() => props.onChoosePlan(item.id)}
                >
                  {props.buttonText}
                </button>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Welcome;
