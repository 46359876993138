import { Steps } from 'antd';
import { useMotionValue, useTransform } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import SectionHeader from '../../components/SectionHeader';
import Title from '../../components/Title';
import { useMobileDetect } from '../../util/hooks';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';

const { Step } = Steps;

const CreateStep = (props) => {
  const [step, setStep] = useState(0);

  const [matchId, setMatchId] = useState('0');

  const {
    register,
    control,
    handleSubmit,
    watch,
    errors,
    formState,
    getValues,
  } = useForm();

  const [responses, setResponses] = useState({});

  const search = useLocation().search;
  const matchIdFromRouter = new URLSearchParams(search).get('matchId');
  const coachIdFromRouter = new URLSearchParams(search).get('coachId');

  console.log(formState);
  console.log(props);

  useEffect(() => {
    console.log('yolo');
    if (matchIdFromRouter) {
      console.log('HIII');
      setStep(1);
    }
  }, [matchIdFromRouter]);

  function renderStep(propStep) {
    switch (propStep) {
      case 1:
        return (
          <Steps current={step} className="steps">
            <Step title="Find your match" className="hide" />
            <Step title="Leave your review" />
          </Steps>
        );
      case 2:
        return (
          <Steps current={step} className="steps">
            <Step title="Find your match" className="hide" />
            <Step title="Leave your review" className="hide" />
            <Step title="Submit your response" />
          </Steps>
        );
      default:
        return (
          <Steps current={step} className="steps">
            <Step title="Find your match" />
          </Steps>
        );
    }
  }

  function renderCurrentChild(propStep) {
    switch (propStep) {
      case 1:
        return (
          <Step2
            formState={formState}
            getValues={getValues}
            setFormState={setResponses}
            setStep={setStep}
            coachId={coachIdFromRouter}
            matchId={matchIdFromRouter}
            control={control}
            handleSubmit={handleSubmit}
          />
        );
      case 2:
        return <Step3 setStep={setStep} />;
      default:
        return (
          <Step1
            formState={formState}
            setFormState={setMatchId}
            setStep={setStep}
          />
        );
    }
  }

  const x = useMotionValue(0);
  const input = [-200, 0, 200];
  const output = [0, 1, 0];
  const opacity = useTransform(x, input, output);

  return (
    <div className="pt-3">
      <div className="container example-container full-page">
        <Title pageTitle="Rate a Match" />
        {useMobileDetect().isDesktop() ? (
          <>
            <SectionHeader
              title="Leaving a review only takes a couple of minutes..."
              subtitle="...But your feedback will help shape the future of youth sports!"
              size={2}
            />
          </>
        ) : (
          ''
        )}
        {useMobileDetect().isDesktop() ? (
          <Steps current={step} className="steps">
            <Step title="Find your match" />
            <Step title="Leave your review" />
            {!coachIdFromRouter && <Step title="Submit your response" />}
          </Steps>
        ) : (
          renderStep(step)
        )}

        {renderCurrentChild(step)}
      </div>
    </div>
  );
};

export default CreateStep;
