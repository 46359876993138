const apiUrl = 'https://nodeproxymys.herokuapp.com/api';

export async function getAllReviewQuestions() {
  const JSONData = await fetch(`${apiUrl}/question-groups`);
  const data = await JSONData.json();
  return data;
}

export async function getMatchById(id) {
  const JSONData = await fetch(`${apiUrl}/matches/${id}`);
  const data = await JSONData.json();
  return data;
}

export async function createReviewResponse(body) {
  const JSONData = await fetch(`${apiUrl}/review-responses`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });
  const data = await JSONData.json();
  return data;
}
