import React from 'react';
import Section from './../Section';
import SectionHeader from './../SectionHeader';
import TeamBios from './../TeamBios';
import './styles.scss';

function TeamBiosSection(props) {
  return (
    <Section color={props.color} size={props.size}>
      <div className="container">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          centered={true}
          size={3}
        />
        <TeamBios
          people={[
            {
              avatar: require('../../assets/referee.png'),
              name: 'OfficiMate',
              role: 'Referee Scheduling App',
              bio:
                'Schedule, track availability, and notify referees of match changes all with one app. OfficiMate helps you get the greatest value from your officials team.',
              twitterUrl: 'https://twitter.com',
              facebookUrl: 'https://facebook.com',
              linkedinUrl: 'https://linkedin.com',
            },
            {
              avatar: require('../../assets/tournament.png'),
              name: 'MYS Tournaments',
              role: 'Tournament/League Scheduling Platform',
              bio:
                'Create and schedule tournaments, manage registrations, and notify coaches / players / parents of any changes to your matches.',
              twitterUrl: 'https://twitter.com',
              facebookUrl: 'https://facebook.com',
              linkedinUrl: 'https://linkedin.com',
            },
            // {
            //   avatar: "https://uploads.divjoy.com/pravatar-150x-16.jpeg",
            //   name: "Syngularity",
            //   role: "Social Gaming Platform",
            //   bio:
            //     "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum consequatur numquam aliquam tenetur ad amet inventore hic beatae, quas accusantium perferendis sapiente explicabo, corporis totam! Labore reprehenderit beatae magnam animi!",
            //   twitterUrl: "https://twitter.com",
            //   facebookUrl: "https://facebook.com",
            //   linkedinUrl: "https://linkedin.com"
            // }
          ]}
        />
      </div>
    </Section>
  );
}

export default TeamBiosSection;
