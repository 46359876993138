import React from "react";
import ContactSection from "./../../components/ContactSection";
import "./styles.scss";
import Title from "../../components/Title";

function ContactPage(props) {
  return (
    <>
    <Title pageTitle="Contact Us" />
    <ContactSection
      color="white"
      size="medium"
      title="Contact Us"
      subtitle=""
      showNameField={true}
      buttonText="Send message"
    />
    </>
  );
}

export default ContactPage;
