import React from "react";
import SectionButton from "../../components/SectionButton";
import SignUpSection from "../../components/SignUpSection";

const Step3 = ({ setStep }) => {
  return (
    <>
      <SignUpSection
        title="Thanks for your feedback!"
        subtitle="Now please prove that you are human!"
        buttonText="Submit Review"
        size="small"
      />
      <SectionButton color="primary" onClick={() => setStep(1)}>
        Previous
      </SectionButton>
    </>
  );
};

export default Step3;
