import * as React from 'react';
import { useEffect, useState } from 'react';
// @ts-ignore
import { Calendar, DateObject } from 'react-native-calendars';

export interface CalendarMarkableProps {
  /**
   * Can multiple dates be selected?
   */
  multi?: boolean;
  /**
   * Array of YYYY-MM-dd Strings to select
   */
  selectedDates?: string[];
  /**
   * Minimum date that can be selected
   */
  minDate?: Date;
  setSelectedDates: (isoArray?: string[]) => void;
}

export default function CalendarMarkable({
  minDate,
  selectedDates = [],
  setSelectedDates,
  multi = false,
  ...props
}: CalendarMarkableProps) {
  const [marked, setMarked] = useState();

  function handleDayPress(value: DateObject) {
    if (multi) {
      if (selectedDates.find((date) => value.dateString === date)) {
        const newDateIso = value.dateString;
        setSelectedDates(selectedDates.filter((date) => date !== newDateIso));
      } else {
        setSelectedDates([...selectedDates, value.dateString]);
      }
    } else {
      if (
        selectedDates.find(
          (date) => new Date(value.dateString).toISOString() === date
        )
      ) {
        setSelectedDates([]);
      } else {
        setSelectedDates([value.dateString]);
      }
    }
  }

  useEffect(() => {
    if (selectedDates.length > 0) {
      const newMarkedDates: any = {};
      selectedDates.forEach((date) => {
        newMarkedDates[date] = { selected: true, marked: true };
      });
      // @ts-ignore
      setMarked(newMarkedDates);
    } else {
      // @ts-ignore
      setMarked({});
    }
  }, [selectedDates]);

  return (
    <Calendar
      minDate={minDate}
      onDayPress={handleDayPress}
      style={{
        maxWidth: 600,
      }}
      marking={{}}
      // disableAllTouchEventsForDisabledDays
      markedDates={marked}
      markingType="dot"
      theme={{
        todayTextColor: 'black',
        selectedDayTextColor: 'black',
        selectedDayBackgroundColor: '#4b9ebf',
        textDisabledColor: 'grey',
        arrowColor: 'black',
      }}
      {...props}
    />
  );
}
