import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import BooleanQuestion from '../BooleanQuestion';
import RatingQuestion from '../RatingQuestion';
import TextQuestion from '../TextQuestion';

function QuestionGroup({ group, responseObj, control }) {
  const [expanded, setExpanded] = useState(true);
  const [touched, setTouched] = useState(false);

  const [completedNum, setCompletedNum] = useState(0);

  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    let completed = 0;
    const newQuestions = group.questions.map((question) => {
      if (question.hasOwnProperty('value')) {
        completed += 1;
      }
      return question;
    });
    setCompletedNum(completed);
    // if(completedNum === group.questions.length && !touched) {
    //   setExpanded(false);
    // }
  }, [group.questions]);

  const handleChange = (id, value) => {
    let completed = 0;
    const newQuestions = group.questions.map((question) => {
      if (question._id === id) {
        question.value = value;
      }
      if (question.hasOwnProperty('value')) {
        completed += 1;
      }
      return question;
    });
    setCompletedNum(completed);
    setQuestions([...newQuestions]);
  };

  return (
    <article className="mt-3 mb-3">
      <div className="title is-spaced is-4">
        <span className="FaqItem__icon icon is-size-5 has-text-primary">
          <button
            className="btn-no-style"
            onClick={() => {
              setExpanded(!expanded);
              setTouched(true);
            }}
          >
            <i
              className={
                'fas' +
                (expanded ? ' fa-minus' : '') +
                (!expanded ? ' fa-plus' : '')
              }
            />
          </button>
        </span>
        <button
          className="btn-no-style"
          onClick={() => {
            setExpanded(!expanded);
            setTouched(true);
          }}
        >
          {group.name}
          <span className="ml-3 light-text">
            ({completedNum}/{group.questions.length})
          </span>
        </button>
      </div>

      <div
        style={{ height: expanded ? 'auto' : 0, overflowY: 'hidden' }}
        className="subtitle"
      >
        {group.questions.map((question) => {
          switch (question.type) {
            case 'boolean':
              return (
                <Controller
                  control={control}
                  name={question._id}
                  render={({ onChange, value }) => (
                    <BooleanQuestion
                      question={question}
                      handleChange={onChange}
                      key={question._id}
                      value={value}
                    />
                  )}
                />
              );
            case 'rating':
              return (
                <Controller
                  control={control}
                  name={question._id}
                  render={({ onChange, value }) => (
                    <RatingQuestion
                      question={question}
                      handleChange={onChange}
                      key={question._id}
                      value={value}
                    />
                  )}
                />
              );
            case 'text':
              return (
                <Controller
                  control={control}
                  name={question._id}
                  render={({ onChange, value }) => (
                    <TextQuestion
                      question={question}
                      handleChange={onChange}
                      key={question._id}
                      value={value}
                    />
                  )}
                />
              );
            default:
              return <></>;
          }
        })}
      </div>
    </article>
  );
}

export default QuestionGroup;
