import React from "react";
import WelcomeSection from "../../components/WelcomeSection";
import "./styles.scss";

function WelcomePage(props) {
  return (
    <WelcomeSection
      color="white"
      size="medium"
      title="Welcome to the Club!"
      subtitle=""
    />
  );
}

export default WelcomePage;
