import React from 'react';
import { Link } from './../../util/router.js';
import Section from './../Section';
import './styles.scss';

function Footer(props) {
  return (
    <Section color={props.color} size={props.size}>
      <div className="FooterComponent__container container">
        <div className="brand left">
          <Link to="/">
            <img src={props.logo} alt="Logo" />
          </Link>
        </div>
        <div className="links right">
          <Link to="/about">About</Link>
          <Link to="/faq">FAQ</Link>
          <Link to="/signup">Signup</Link>
          <Link to="/privacy">Privacy</Link>
        </div>
        <div className="social right"></div>
        <div className="copyright left">{props.copyright}</div>
      </div>
      <div className="FooterComponent__container container">
        Icons made by {'  '}
        <a className="ml-1 mr-1" href="https://www.freepik.com" title="Freepik">
          Freepik
        </a>
        from{' '}
        <a className="ml-1" href="https://www.flaticon.com/" title="Flaticon">
          www.flaticon.com
        </a>
      </div>
    </Section>
  );
}

export default Footer;
