import { Input } from 'antd';
import React from 'react';

const { TextArea } = Input;

export default function TextQuestion({ question, handleChange }) {
  function onChange(e) {
    handleChange(e.target.value);
  }

  return (
    <div className="mt-3">
      <h2>{question.prompt}</h2>
      <div className="w-80 m-auto">
        <TextArea
          value={question.value}
          onChange={onChange}
          placeholder="Type your response here"
          autosize={{ minRows: 3, maxRows: 5 }}
        />
      </div>
    </div>
  );
}
