import { Rate } from 'antd';
import React from 'react';

export default function RatingQuestion({ question, handleChange, value }) {
  const desc = ['Terrible!', 'Bad.', 'Decent...', 'Good.', 'Great!'];

  function onChange(val) {
    handleChange(val);
  }

  return (
    <div className="mt-2 mb-3">
      <h2>{question.prompt}</h2>
      <Rate
        allowClear={false}
        className="mt-1"
        tooltips={desc}
        onChange={onChange}
        value={value}
      />
      {value ? <div className="mt-1 text-info">{desc[value - 1]}</div> : ''}
    </div>
  );
}
