import React from 'react';
import Section from '../Section';
import SectionHeader from '../SectionHeader';
import Welcome from '../Welcome';
import './styles.scss';

function ThankYouSection(props) {
  return (
    <Section color={props.color} size={props.size} id="welcome">
      <div
        style={{ height: '80vh' }}
        className="ThankYouSection__container container"
      >
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          centered={true}
          size={3}
        />
        <Welcome
          buttonText="Return to home"
          onChoosePlan={(planId) => {
            // Add your own payments logic here
            window.location.href = '/';
          }}
          items={[
            {
              id: 'monthly',
              timespan: 'Thanks for your feedback!',
              description:
                "Your feedback will be used to help us create the best matches possible for our players. You can close this page if you'd like.",
            },
          ]}
        />
      </div>
    </Section>
  );
}

export default ThankYouSection;
