import React, { useState } from "react";
import FormStatus from "./../FormStatus";
import FormField from "./../FormField";
import SectionButton from "./../SectionButton";
import { Link, useRouter } from "./../../util/router.js";
import "./styles.scss";

function Auth(props) {
  const router = useRouter();
  // State for all inputs
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");

  const [mode, setMode] = useState(props.mode);

  // Whether to show errors
  // We set to true if they submit and there are errors.
  // We only show errors after they submit because
  // it's annoying to see errors while typing.
  const [showErrors, setShowErrors] = useState(false);

  function setSignin(e) {
    e.preventDefault();
    if (mode === "signup") {
      setMode("signin");
    } else {
      setMode("signup");
    }
  }

  // Error array we'll populate
  let errors = [];

  // Function for fetching error for a field
  const getError = field => {
    return errors.find(e => e.field === field);
  };

  // Function to see if field is empty
  const isEmpty = val => val.trim() === "";

  // Add error if email empty
  if (["signin", "signup", "forgotpass"].includes(mode)) {
    if (isEmpty(email)) {
      errors.push({
        field: "email",
        message: "Please enter an email"
      });
    }
  }

  // Add error if password empty
  if (["signin", "signup", "changepass"].includes(mode)) {
    if (isEmpty(pass)) {
      errors.push({
        field: "pass",
        message: "Please enter a password"
      });
    }
  }

  // Handle form submission
  const handleSubmit = () => {
    // If field errors then show them
    if (errors.length) {
      setShowErrors(true);
    } else {
      // Otherwise call onSubmit with email/pass
      if (props.onSubmit) {
        props.onSubmit({
          email,
          username: email,
          pass
        });
      }
    }
  };

  return (
    <div className="Auth">
      {props.status && props.status.message && (
        <FormStatus type={props.status.type} message={props.status.message} />
      )}

      <form
        onSubmit={e => {
          e.preventDefault();
          handleSubmit();
          router.push("/welcome");
        }}
      >
        {["signup", "signin", "forgotpass"].includes(mode) && (
          <FormField
            value={email}
            type="email"
            placeholder="Email"
            error={showErrors && getError("email")}
            onChange={value => setEmail(value)}
          />
        )}

        {["signup", "signin", "changepass"].includes(mode) && (
          <FormField
            value={pass}
            type="password"
            placeholder="Password"
            error={showErrors && getError("pass")}
            onChange={value => setPass(value)}
          />
        )}

        <div className="field">
          <p className="control ">
            <SectionButton
              parentColor={props.parentColor}
              size="medium"
              fullWidth={true}
              state={
                props.status && props.status.type === "pending"
                  ? "loading"
                  : "normal"
              }
            >
              {props.buttonText}
            </SectionButton>
          </p>
        </div>

        {["signup", "signin"].includes(mode) && (
          <div className="Auth__bottom-link has-text-centered">
            {mode === "signup" && (
              <>
                Have an account already? {` `}
                <button onClick={setSignin} className="btn-no-style link">
                  Sign in
                </button>
              </>
            )}

            {mode === "signin" && (
              <>
                <button onClick={setSignin} className="btn-no-style link">
                  Create an account
                </button>
                {/* <Link to="/forgotpass">Forgot password</Link> */}
              </>
            )}
          </div>
        )}
      </form>
    </div>
  );
}

export default Auth;
