import React from "react";
import Section from "./../Section";
import SectionHeader from "./../SectionHeader";
import Faq from "./../Faq";
import "./styles.scss";

function FaqSection(props) {
  return (
    <Section color={props.color} size={props.size}>
      <div className="container">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          centered={true}
          size={3}
        />
        <Faq
          items={[
            {
              question: "How much does this cost?",
              answer:
                "It's free. And we don't sell your data either. This website is made possible by other products within the MYS brand, primarily OfficiMate."
            },
            {
              question: "Can I use OfficiRate for any sport?",
              answer:
                "Absolutely! While the rating form may differ from sport to sport, we track stats from every sport that has an official/referee."
            },
            {
              question: "How do you prevent fake ratings?",
              answer:
                "Our system flags accounts if a user's activity seems suspicious. Once an account is flagged, our team manually reviews that account and determines whether or not to ban their IP address."
            },
            {
              question: "Do you have a mobile app that works offline?",
              answer:
                "Not yet. However, our website uses a technology that stores your review on your mobile device until you have internet connection. Make sure to connect to the internet when possible and we'll automatically sync up with your response(s)."
            }
          ]}
        />
      </div>
    </Section>
  );
}

export default FaqSection;
